<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <div class="page-wrapper-table-header-left">
          <base-input
            v-model="query"
            type="search"
            class="search"
            prepend-icon="far fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
        </div>
        <div class="page-wrapper-table-header-right">
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            :placeholder="$t('COMMON.PER_PAGE')"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="page-wrapper-table-body">
        <loading-panel v-if="loading"></loading-panel>
        <div v-else class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="equipmentCategories"
            :empty-text="$t('COMMON.NO_SEARCH_RESULT')"
            @sort-change="sortChange"
          >
            <el-table-column
              :label="$t('EQUIPMENT_CATEGORIES.EQUIPMENT_CATEGORY_NAME')"
              prop="name"
              sortable="custom"
              min-width="200"
            >
            </el-table-column>
            <el-table-column :label="$t('COMMON.COMMENT')" min-width="200">
              <template v-slot="{ row }">
                <div v-html="row.excerpt"></div>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.CREATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="120"
            >
              <template v-slot="{ row }">
                {{ $formatDate(row.created_at) }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" min-width="70">
              <div slot-scope="{ row }" class="table-actions">
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    <i class="far fa-ellipsis-v"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="actions-dropdown">
                    <el-dropdown-item
                      v-if="
                        $currentUserCan(
                          $permissions.PERM_VIEW_EQUIPMENT_CATEGORIES
                        )
                      "
                    >
                      <a
                        type="text"
                        @click="viewEquipmentCategory(row)"
                        class="table-action view"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-eye.svg" alt="icon" />
                        </span>
                        <span class="text">{{
                          $t("COMMON.VIEW_DETAILS")
                        }}</span>
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="
                        $currentUserCan(
                          $permissions.PERM_EDIT_EQUIPMENT_CATEGORIES
                        )
                      "
                    >
                      <a
                        type="text"
                        @click="editEquipmentCategory(row)"
                        class="table-action edit"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-edit.svg" alt="icon" />
                        </span>
                        <span class="text">
                          {{ $t("COMMON.EDIT") }}
                        </span>
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="
                        $currentUserCan(
                          $permissions.PERM_DELETE_EQUIPMENT_CATEGORIES
                        )
                      "
                    >
                      <a
                        type="text"
                        @click="deleteEquipmentCategory(row.id)"
                        class="table-action delete"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-delete.svg" alt="icon" />
                        </span>
                        <span class="text">
                          {{ $t("COMMON.DELETE") }}
                        </span>
                      </a>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div
        v-if="equipmentCategories.length > 0"
        slot="footer"
        class="page-wrapper-table-footer"
      >
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
    <div
      v-if="(equipmentCategories.length === 0) & !loading & isEmptyFilters()"
      class="no-data"
    >
      <div class="no-data-inner">
        <img src="/img/kw-empty-box.svg" alt="icon" />
        <p>{{ $t("EQUIPMENT_CATEGORIES.NO_DATA") }}</p>
        <base-button
          class="kw-button add"
          icon
          size="sm"
          @click="onAddEquipmentCategory"
          v-if="$currentUserCan($permissions.PERM_CREATE_EQUIPMENT_CATEGORIES)"
        >
          <span class="btn-inner--icon">
            <i class="far fa-plus"></i>
          </span>
          <span class="btn-inner--text">{{
            $t("EQUIPMENT_CATEGORIES.ADD_EQUIPMENT_CATEGORY")
          }}</span>
        </base-button>
      </div>
    </div>
    <div
      v-if="(equipmentCategories.length === 0) & !isEmptyFilters()"
      class="no-data"
    >
      <div class="no-data-inner">
        <img src="/img/kw-empty-box.svg" alt="icon" />
        <p>{{ $t("COMMON.NO_SEARCH_RESULT") }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import LoadingPanel from "@/components/LoadingPanel.vue";

export default {
  name: "equipment-category-list-table",

  components: {
    BasePagination,
    LoadingPanel,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {},

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      equipmentCategories: [],
      loading: true,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "",
        };

        await this.$store.dispatch("equipmentCategories/list", params);
        this.equipmentCategories =
          this.$store.getters["equipmentCategories/list"];
        this.total = this.$store.getters["equipmentCategories/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    editEquipmentCategory(customer) {
      this.$emit("onEditEquipmentCategory", customer);
      /* this.$router.push({
        name: "Edit Customer",
        params: { id: row.id },
      }); */
    },

    async deleteEquipmentCategory(id) {
      const confirmation = await swal.fire({
        text: this.$t("EQUIPMENT_CATEGORIES.DELETE_THIS_EQUIPMENT_CATEGORY"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.CANCEL"),
        confirmButtonText: this.$t("COMMON.DELETE_ITEM"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("equipmentCategories/destroy", id);
          await this.getList();
          /* this.$notify({
            type: "success",
            message: this.$t("EQUIPMENT_CATEGORIES.EQUIPMENT_CATEGORY_DELETED"),
          }); */
          swal.fire({
            text: this.$t("EQUIPMENTS.DELETE_THIS_EQUIPMENT_SUCCESS"),
            type: "info",
            customClass: {
              popup: "delete-confirm",
            },
            buttonsStyling: false,
            showCancelButton: false,
            confirmButtonText: this.$t("COMMON.END"),
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-warning",
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    onAddEquipmentCategory() {
      this.$emit("onAddEquipmentCategory");
    },

    viewEquipmentCategory(equipmentCategory) {
      this.$emit("onViewEquipmentCategory", equipmentCategory);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
    isEmptyFilters() {
      return (
        !this.query && !this.selectedOrganization && !this.selectedLocations
      );
    },
  },
};
</script>
